import React from "react";
import Img from "gatsby-image";
import Slider from "react-slick";
import styled from "styled-components";
import {
  StyledH1,
  StyledSectionWrapper,
  ContentWrapperInner,
  StyledWorkImg,
} from "../theme/styles";
import t from "../theme/theme";
import Arrow from "../../static/images/arrow.svg";

const sliderSettings = {
  dots: true,
  slidesToShow: 1,
  infinite: true,
  fade: true,
};

const StyledSlideshowWrapper = styled.div`
  width: 80%;
  @media (min-width: ${t.desktop}) {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledWorkTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: ${t.spacing(2)};
`;

const StyledSlider = styled(Slider)`
  transition: opacity 0.2s ease-in-out;
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;

  p {
    margin: 0;
  }

  .slick-slide {
    min-height: 50vh;
    font-family: "dolly", serif;
    font-style: italic;
    padding: 0 1.5em;
    @media (min-width: ${t.desktop}) {
      min-height: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    z-index: 2;
    top: auto;
    bottom: ${t.spacing(3)};
    left: auto;
    right: auto;
    transform: none;
    width: auto;
    height: auto;
    font-size: ${t.spacing(2)};
    color: ${t.text};
    display: flex !important;
    align-items: center;
    &:before {
      display: flex;
      color: ${t.text};
      content: "";
      opacity: 1;
      background: url(${Arrow});
      width: ${t.spacing(5)};
      height: ${t.spacing(5)};
      transform: rotate(270deg);
      margin: 0 ${t.spacing(1)};
      @media (min-width: ${t.desktop}) {
        transform: none;
        margin-bottom: ${t.spacing(2)};
      }
    }
    @media (min-width: ${t.desktop}) {
      position: fixed;
      left: 50%;
      flex-direction: column;
    }
  }

  .slick-prev {
    @media (min-width: ${t.desktop}) {
      top: ${t.spacing(2)};
      bottom: auto;
    }
  }

  .slick-next {
    right: 0;

    &:before {
      order: 1;
      transform: rotate(90deg);
      @media (min-width: ${t.desktop}) {
        transform: rotate(180deg);
        margin-top: ${t.spacing(2)};
      }
    }
  }

  .slick-dots {
    display: none !important;
    @media (min-width: ${t.desktop}) {
      position: absolute;
      top: 10vh;
      right: ${t.spacing(3)};
      bottom: auto;
      width: auto;
      display: flex !important;
      flex-direction: column;

      li {
        margin: ${t.spacing(2)} ${t.spacing(1)};
        &.slick-active {
          text-decoration: underline;
        }
        button {
          font-size: ${t.spacing(2)};
          color: ${t.blue};
          &:before {
            display: none;
          }
        }
      }
    }
  }

  .slick-list {
    @media (min-width: ${t.desktop}) {
      overflow: visible;
      width: 100%;
      height: 100%;

      .slick-track {
        height: 100%;
      }
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: ${t.desktop}) {
    //max-width: 25vw;
    margin: 0 auto;
    width: 100%;
  }
`;

const WorkSlideshow = ({ work }) => {
  const data = work.data.allFile.edges[0].node;
  const workItems = data.childMarkdownRemark.frontmatter.work_items;
  console.log(workItems);
  return (
    <StyledSectionWrapper>
      <StyledH1>{data.name}</StyledH1>
      <StyledSlideshowWrapper>
        <StyledSlider {...sliderSettings}>
          {workItems.map((item, index) => {
            return (
              <div key={index}>
                <ContentWrapper>
                  <ContentWrapperInner>
                    <StyledWorkImg src={item.image.publicURL} />
                    {/*<Img fluid={item.image.childImageSharp.fluid} />*/}
                    <StyledWorkTitle>{item.work_title}</StyledWorkTitle>
                    <p>{item.material}</p>
                    <p>{item.dimensions}</p>
                    <p>{item.date}</p>
                  </ContentWrapperInner>
                </ContentWrapper>
              </div>
            );
          })}
        </StyledSlider>
      </StyledSlideshowWrapper>
    </StyledSectionWrapper>
  );
};

export default WorkSlideshow;
